import { loanType, transactionType } from '../enum/kyc.enum';

const id_configuration = {
  country_code: 'ID',
  currency: 'IDR',
  va_detail: {
    bca_va_prefix: '12999',
    sinarmas_va_prefix: '8299',
  },
  date_format: {
    date: 'dd MMMM yyyy',
    date_short: 'yyyy-MM-dd',
    date_short_moment: 'YYYY-MM-DD',
    date_short_dmy: 'dd/MM/yyyy',
    date_time: 'dd MMMM yyyy HH:mm:ss',
    date_time_not_second: 'yyyy-MM-dd HH:mm',
    locale: 'id',
    timezone: 'Asia/Jakarta',
  },
  number_format: {
    align: 'left',
    allowNegative: false,
    decimal: '.',
    precision: 2,
    prefix: 'Rp',
    suffix: '',
    thousands: ',',
    locale: 'id',
  },
  pipe: {
    precision: '1.2-2',
  },
  language: {
    default: 'en',
    show: true,
  },
  loan: {
    underwriting: {
      guaranteeFee: {
        show: false,
      },
    },
  },
  phoneValidation: {
    code: '+62',
    phoneNumberLengthWithCode: [12, 13, 14, 15],
    phoneNumberLengthWithoutCode: [9, 10, 11, 12],
  },
  enable_mail_disbursal: true,
  enable_ctos: false,
  enable_questnet: false,
  enable_pefindo: true,
  show_generate_instruction: false,
  display_crowdfunding_segmentation: true,
  display_bulk_investor_categories: true,
  should_auto_send_activation_notification: false,
  primary_ic_type_id: 3, // KTP
  show_bank_integration_section: true,
  show_multiple_virtual_account_number: {
    investor: true,
    borrower: true,
  },
  hide_blank_dropdown_options: {
    industry: false,
  },
  show_user_type_column_in_facility_user: true,
  show_bca_va_reconciliation_upload: true,
  use_custom_investor_management_pagination: false,
  use_custom_borrower_management_pagination: false,
  use_custom_partner_management_pagination: false,
  add_doc_type_and_loan_code_to_loan_agreement_filename: true,
  defaultBranch: 'JKT',
  enableGSTCalculationForRepayment: true,
  gstRate: 11,
  show_distribution_gst: true,
  show_risk_category_fields: false,
  show_cod_options: true,
  show_sik_history: true,
  kycSubstitution: {
    APPLICATION: 'Application',
    KYC: 'APF Paylater Micro KYC',
    BNPL: 'APF BNPL Micro',
    VCC: 'APF Paylater Micro',
    TERM_LOAN: loanType.TERM_LOAN,
    CREDIT_LINE: loanType.CREDIT_LINE,
    ONLY_PARTNERS: ['k24'],
  },
  showFacilityContractNumber: true,
  showFacilityDigitalSignature: true,
  show_compliance_validation_reason: true,
  enableSubscribeMainToProxyLoanDocTypes: true,
  inclusive_withholding_tax: false,
  has_usd_to_local_currency_conversion: false,
  offerTabDigiSign: true,
  showCompanyDFConfig: true,
  calculator: {
    settlement: {
      show_calculated_early_repayment: true,
      show_gst: true,
    },
  },
  defaultFundingRestriction: true,
  additionInfoTab: true,
  enableLoanFinancingModel: true,
  allowFacilityTypeFinanceModel: [], //empty array for All facility types
  enableCalculateRepaymentFee: true,
  facility: {
    showFlag: true,
  },
  enablePushToSilkRoad: false,
  silkRoad2faRequired: false,
  useIDInvestorChannleing: true,
  showComplianceSIK: true,
};

export default id_configuration;
