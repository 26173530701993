import { AfterViewInit, AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { StaffService } from '../../services/staff.service';
import { ProfileService } from '../../services/profile.service';
import { environment } from '../../environments/environment';
import {
  SG_BOLT_PRODUCT_ID,
  MY_BOLT_PRODUCT_ID,
  ID_M1CB_PRODUCT_ID,
  ID_M1CB_BRAVEN_PRODUCT_ID,
  MY_BOLT_GRAB_PRODUCT_ID,
  ID_M1CB_RED_RAVEN_PRODUCT_ID,
  ID_M1CB_SILVER_RAVEN_PRODUCT_ID,
  ID_M1CB_WHITE_RAVEN_PRODUCT_ID,
  ID_M1CB_COPPER_RAVEN_PRODUCT_ID,
  MY_ECOM_FINANCING_PRODUCT_ID,
} from '../../constants/micro-loan.constants';
import { MicroLoanDetailSharedVariable } from '../../micro-loan-detail-shared-variable';
import { PermissionService } from '../../services/permission';
import { FeatureFlagService } from '../../services/feature-flag.service';
import CONFIGURATION from '../../configuration/configuration';

import { Observable } from 'rxjs';
import { isLocalDevelopment } from '../../configuration/configuration';
import { Menu } from '../../models/navigation.model';
import { generateNavigation } from '../../shared/route-util';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  providers: [NavigationService, MicroLoanDetailSharedVariable, PermissionService],
})
export class NavigationComponent implements OnInit, AfterViewInit, AfterViewChecked {
  enableCollection = true;
  enableMasterData = false;
  menus: Menu[];
  hasMenuChanged = false;
  featureFlagObservable: Observable<any>;
  microLoanProductId: any;
  userName = {};
  microLoanProductList: any;
  username: string;
  screens: Array<string> = [];
  userRoles: Array<any> = [];
  countryId: string;
  email: string;
  environmentName: string;
  institution: string;
  defaultRole: string;
  environment: any;
  modalkuCountryList: any;
  kycSubstitution: any;
  hideNonMKItem = environment.hideNonMKItem && !isLocalDevelopment();
  hideNonFSItem = environment.hideNonFSItem && !isLocalDevelopment();

  constructor(
    private route: ActivatedRoute,
    private microLoanDetailSharedVariable: MicroLoanDetailSharedVariable,
    private navigationService: NavigationService,
    private staffService: StaffService,
    private profileService: ProfileService,
    private router: Router,
    private permissionService: PermissionService,
    private featureFlagService: FeatureFlagService
  ) {
    this.countryId = '';
    this.environmentName = environment.name;
    this.environment = environment;

    this.institution = '';
    this.microLoanProductList = '';
    this.microLoanProductId = {
      m1cb: ID_M1CB_PRODUCT_ID,
      braven: ID_M1CB_BRAVEN_PRODUCT_ID,
      silverRaven: ID_M1CB_SILVER_RAVEN_PRODUCT_ID,
      redRaven: ID_M1CB_RED_RAVEN_PRODUCT_ID,
      copperRaven: ID_M1CB_COPPER_RAVEN_PRODUCT_ID,
      whiteRaven: ID_M1CB_WHITE_RAVEN_PRODUCT_ID,
      boltmy: MY_BOLT_PRODUCT_ID,
      boltmygrab: MY_BOLT_GRAB_PRODUCT_ID,
      boltsg: SG_BOLT_PRODUCT_ID,
      ecomFinancingMY: MY_ECOM_FINANCING_PRODUCT_ID,
      islamicMicrofinancingMY: environment.productIds.islamicMicrofinancingMY,
      islamicEcomfinancingMY: environment.productIds.islamicEcommerceMY,
      boltmycgcd: environment.productIds.boltMyCgcd,
    };
    this.microLoanDetailSharedVariable.countryCode = '';
    this.modalkuCountryList = this.profileService.getModalkuCountryList();
  }

  ngOnInit(): void {
    this.menus = generateNavigation(this.enableCollection, this.enableMasterData);
    this.route.params.subscribe(() => {
      this.staffService.getCurrentUser().then((userResponse) => {
        // Save user profile into localStorage: some other components need to read user profile from localStorage.
        this.profileService.saveUserProfile(userResponse);
        this.permissionService.setScreenAccess(userResponse.screens);
        this.userName = userResponse.username;
        this.email = userResponse.username;
        if (this.modalkuCountryList.indexOf(this.profileService.getCountryId()) > -1) {
          this.email = userResponse.username.substring(0, userResponse.username.lastIndexOf('@'));
        }
        this.screens = userResponse.screens;
        this.defaultRole = userResponse['default_role'].toLowerCase();
        this.countryId = this.defaultRole.split('_')[1].toUpperCase();

        for (const role of userResponse.roles) {
          if (role !== this.defaultRole) {
            this.userRoles.push({
              name: role,
              label: 'Switch to ' + role,
            });
          }
        }

        if (this.hideNonMKItem) {
          this.userRoles = this.userRoles.filter((element) =>
            element.name.toUpperCase().endsWith('_ID')
          );
          if (this.countryId !== 'ID') {
            if (this.userRoles.length) {
              this.switchCountry(this.userRoles[0]);
            } else {
              this.logOut(false);
            }
          }
        } else if (this.hideNonFSItem) {
          this.userRoles = this.userRoles.filter(
            (element) => !element.name.toUpperCase().endsWith('_ID')
          );
          if (this.countryId === 'ID') {
            if (this.userRoles.length) {
              this.switchCountry(this.userRoles[0]);
            } else {
              this.logOut(false);
            }
          }
        }

        this.kycSubstitution = CONFIGURATION.kycSubstitution;
        switch (this.countryId) {
          case 'SG':
            this.institution = 'Funding Societies';
            break;
          case 'ID':
            this.institution = 'Modalku';
            break;
          case 'MY':
            this.institution = 'Funding Societies MY';
            break;
          case 'TH':
            this.institution = 'Funding Societies TH';
            break;
          case 'ZV':
            this.institution = 'FS Capital VND';
            break;
          case 'VU':
            this.institution = 'FS Vietnam USD';
            break;
          case 'ZI':
          case 'ZU':
            this.institution = 'FS Capital';
            break;
          default:
            this.institution = 'Funding Societies';
            break;
        }
      });
    });
    const { collection, enableLinksToMasterCustomerDataUi } =
      this.featureFlagService.getFeatureFlagKeys();
    this.featureFlagObservable = this.featureFlagService.getFeatureFlagObservable();
    this.featureFlagObservable.subscribe((flags) => {
      if (
        this.enableCollection != flags[collection] ||
        this.enableMasterData != flags[enableLinksToMasterCustomerDataUi]
      ) {
        this.enableCollection = flags[collection];
        this.enableMasterData = flags[enableLinksToMasterCustomerDataUi];
        this.menus = generateNavigation(
          flags[collection],
          flags[enableLinksToMasterCustomerDataUi]
        );
        this.hasMenuChanged = true;
      }
    });
  }

  ngAfterViewInit() {
    (<any>$('#side-menu')).metisMenu();
  }

  ngAfterViewChecked() {
    if (this.hasMenuChanged) {
      (<any>$('#side-menu')).metisMenu('dispose');
      (<any>$('#side-menu')).metisMenu();
      this.hasMenuChanged = false;
    }
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

  checkRoute(route: string) {
    this.router
      .navigate([route])
      .then(() => {
        // Do nothing if route exists
      })
      .catch((e) => {
        let url;
        if (isLocalDevelopment()) {
          url = `${this.environment.base_website_url}${route}`;
        } else {
          url = `${window.location.origin}${route}`;
        }
        window.open(url, '_self');
      });
  }

  isRouteExistInScreens(validationRoutes: [] = []): boolean {
    return !!validationRoutes.some((route) => this.screens.indexOf(route) != -1);
  }

  isAccessibleMenu(menu: Menu): boolean {
    if (menu.hide) {
      return false;
    }
    if (menu.country?.length) {
      return menu.country.indexOf(this.countryId) !== -1;
    }
    if (!menu.children?.length && menu.validateRoute) {
      return this.screens.includes(menu.screenRoute || menu.route);
    }
    return true;
  }

  switchCountry(role: any) {
    this.profileService.setCountryId(role.name.split('_')[1].toUpperCase());
    this.profileService.setCurrentRole(role.name);
    this.staffService.updateUserDefaultRole(this.email, role.name).then(() => {
      this.logOut();
    });
  }

  logOut(reload = true) {
    this.navigationService.logout().subscribe(() => {
      this.featureFlagService.closeLdClient();
      localStorage.removeItem('oauthToken');
      if (!reload) {
        window.location.href = environment.Logout_Page_URL + window.location.origin;
      }
      window.location.reload();
    });
  }
}
