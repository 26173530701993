const zu_configuration = {
  country_code: 'ZU',
  currency: 'USD',
  date_format: {
    date: 'dd MMMM yyyy',
    date_short: 'yyyy-MM-dd',
    date_short_moment: 'YYYY-MM-DD',
    date_short_dmy: 'dd/MM/yyyy',
    date_time: 'dd MMMM yyyy HH:mm:ss',
    date_time_not_second: 'yyyy-MM-dd HH:mm',
    locale: 'id',
    timezone: 'Asia/Jakarta',
  },
  number_format: {
    align: 'left',
    allowNegative: false,
    decimal: '.',
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    locale: 'id',
  },
  pipe: {
    precision: '1.2-2',
  },
  language: {
    default: 'en',
    show: true,
  },
  loan: {
    underwriting: {
      guaranteeFee: {
        show: false,
      },
    },
  },
  show_generate_instruction: true,
  primary_ic_type_id: 28, // KTP
  hide_blank_dropdown_options: {
    industry: false,
  },
  phoneValidation: {
    code: '+62',
    phoneNumberLengthWithCode: [12, 13, 14, 15],
    phoneNumberLengthWithoutCode: [9, 10, 11, 12],
  },
  show_user_type_column_in_facility_user: true,
  show_bca_va_reconciliation_upload: true,
  use_custom_investor_management_pagination: false,
  use_custom_borrower_management_pagination: false,
  use_custom_partner_management_pagination: false,
  add_doc_type_and_loan_code_to_loan_agreement_filename: false,
  defaultBranch: 'JKT',
  show_distribution_gst: false,
  show_risk_category_fields: true,
  inclusive_withholding_tax: false,
  has_usd_to_local_currency_conversion: false,
  enableLoanFinancingModel: true,
  allowFacilityTypeFinanceModel: [], //empty array for All facility types
  enableCalculateRepaymentFee: true,
  silkRoad2faRequired: false,
  facility: {
    showFlag: true,
  },
};

export default zu_configuration;
