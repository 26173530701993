import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router
} from '@angular/router';
import { StaffService } from '../../services/staff.service';
import { getResolvedUrl, getConfiguredUrl } from './route-util';
import { PermissionService } from './permission.service';
import { matchScreens } from './util';
import { environment } from '../../environments/environment';

// match case insensitive /microloan/(numbers)/
// Group 1: product id
const MICROLOAN_REGEX = /^\/+microloan\/+(\d+)\/*$/i;

// match case insensitive /microloan/(numbers)/details/(numbers)**
// Group 1: product id
// Group 2: loan id
const MICROLOAN_DETAILS_REGEX = /^\/+microloan\/+(\d+)\/+details\/+(\d+)/i;

@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(
    private permissionService: PermissionService,
    private staffService: StaffService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    const screens = this.permissionService.getScreenAccess();
    if (!screens.length) {
      return this.staffService.getCurrentUser().then(
        user => {
          const { screens } = user;
          this.permissionService.setScreenAccess(screens);
          return this.isAllowed(route, screens);
        }
      );
    } else {
      return this.isAllowed(route, screens);
    }
  }

  private isAllowed(route: ActivatedRouteSnapshot, screens: string[]): boolean {
    let url = getResolvedUrl(route);
    url = this.processMicroloanUrl(url) || this.processUrl(url) || getConfiguredUrl(route);
    const hasAccess = matchScreens(url, screens);
    if (!hasAccess) {
      this.router.navigate(['not-found-page'], { queryParams: { url } });
    }
    return hasAccess;
  }

  // TODO: remove after https://fundingasiagroup.atlassian.net/browse/ITS-564 complete.
  private processMicroloanUrl(url: string): string | null {
    let matches = url.match(MICROLOAN_REGEX);
    if (matches) {
      const [, productId] = matches;
      const urlMap = {
        '21': 'm1cb',
        '25': 'boltmy',
        '11': 'boltsg',
        '27': 'braven',
        '63': 'redraven',
        '67': 'silverraven',
        '79': 'copperraven',
        '82': 'whiteraven',
        '65': 'boltmy-grab',
        '57': 'my-ecom'
      };
      urlMap[environment.productIds.islamicMicrofinancingMY] = 'boltmy'
      urlMap[environment.productIds.islamicEcommerceMY] = 'my-ecom'
      urlMap[environment.productIds.boltMyCgcd] = 'my-ecom'
      return url.replace(productId, urlMap[productId]);
    }

    matches = url.match(MICROLOAN_DETAILS_REGEX);
    if (matches) {
      const [, productId, loanId] = matches;
      const regex = new RegExp(`/${productId}/|/${loanId}/`, 'g');
      return url.replace(regex, '/:id/');
    }

    return null;
  }

  // TODO: remove after all routes and screens sync-ed.
  private processUrl(url: string): string | null {
    // uuid replacement to id for docgen templates.
    if(url.match(/^\/loan\/product\/details\/\d+\/template\/[0-9\-a-f]{36}\/edit/)) {
      url = url.replace(/[0-9\-a-f]{36}/, ':id');
      return url.replace(/\d+/g, ':id');
    }
    if (/\d/.test(url) && !['/facility/v2/lifecycle', '/member/qualified-leads/', '/utilities/esign-templates/'].some(u => url.includes(u))) {
      return url.replace(/\d+/g, ':id');
    }
    return null;
  }
}
