import { FACILITY_PRODUCT_TYPE } from '../enum/facility-enum';

const my_configuration = {
  country_code: 'MY',
  currency: 'MYR',
  date_format: {
    date: 'dd MMMM yyyy',
    date_short: 'yyyy-MM-dd',
    date_short_moment: 'YYYY-MM-DD',
    date_short_dmy: 'dd/MM/yyyy',
    date_time: 'dd MMMM yyyy HH:mm:ss',
    date_time_not_second: 'yyyy-MM-dd HH:mm',
    locale: 'en-MY',
    timezone: 'Asia/Kuala_Lumpur',
  },
  number_format: {
    align: 'left',
    allowNegative: false,
    decimal: '.',
    precision: 2,
    prefix: 'RM',
    suffix: '',
    thousands: ',',
    locale: 'en',
  },
  pipe: {
    precision: '1.2-2',
  },
  language: {
    default: 'en',
    show: false,
  },
  enable_ctos: true,
  enable_questnet: false,
  enable_pefindo: false,
  show_generate_instruction: true,
  changeRequest: {
    loanInfo: {
      showGrade: true,
    },
  },
  loan: {
    underwriting: {
      guaranteeFee: {
        show: true,
      },
    },
  },
  hide_blank_dropdown_options: {
    industry: false,
  },
  phoneValidation: {
    code: '+60',
    phoneNumberLengthWithCode: [10, 11, 12, 13],
    phoneNumberLengthWithoutCode: [7, 8, 9, 10],
  },
  show_user_type_column_in_facility_user: true,
  primary_ic_type_id: 7, // MyKad
  show_bca_va_reconciliation_upload: false,
  use_custom_investor_management_pagination: false,
  use_custom_borrower_management_pagination: false,
  use_custom_partner_management_pagination: false,
  add_doc_type_and_loan_code_to_loan_agreement_filename: false,
  defaultBranch: 'KL',
  show_distribution_gst: false,
  show_risk_category_fields: true,
  inclusive_withholding_tax: false,
  showCompanyDFConfig: true,
  has_usd_to_local_currency_conversion: false,
  calculator: {
    settlement: {
      show_calculated_early_repayment: true,
    },
  },
  allowFacilityCreationWithAllTypes: true,
  autoInvestment: {
    hasIslamicProduct: true,
  },
  additionInfoTab: true,
  statements: {
    interest_received: 'Returns Received',
    late_interest_fee_received: 'Compensation Received',
    early_interest_fee_received: 'Early Returns Received',
  },
  enableContra: true,
  enableLoanFinancingModel: true,
  allowFacilityTypeFinanceModel: [FACILITY_PRODUCT_TYPE.AP],
  enablePushToSilkRoad: false,
  silkRoad2faRequired: true,
  showFacilityContractDate: true,
  smeIssuer: {
    display: true,
    items: [
      'Yes',
      'No - MNC',
      'No - Gov Linked Corp',
      'No - State Owned Enterprise',
      'No - Public listed',
      'No - Others',
    ],
  },
};

export default my_configuration;
